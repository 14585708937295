<template>
	<v-container fluid>
		<v-tabs-items touchless class="transparent" v-model="selectedAppBarTab">
			<v-tab-item v-for="tab in tabs" :value="tab.key" :key="tab.key" :href="tab.key" />
		</v-tabs-items>
		<FilterHeader :key="filterHeaderRefreshKey" @newValues="updateValues($event)"
			:use-date="selectedTab.filterFields.useDate" :use-month="selectedTab.filterFields.useMonth"
			:use-channels="selectedTab.filterFields.useChannels" :use-locations="false"
			:use-staff="selectedTab.filterFields.useStaff" :use-day-starts-at="selectedTab.filterFields.useDayStartsAt"
			:use-day-ends-at="selectedTab.filterFields.useDayEndsAt"
			:initialize-time-to-today="selectedTab.filterFields.initializeTimeToToday" />
		<div :key="transientRefreshKey"
			v-if="selectedAppBarTab === selectedTab.key && selectedTab.key !== 'end_of_day'">
			<TransientDash v-for="date in datesToUse" :key="date.key" :title="titleWithDate(date)"
				:namespace="selectedTab.namespace" :config="{}"
				:fixed-header="selectedTab.transientDashOptions.useFixedHeader" :disclaimer="selectedTab.disclaimer"
				:channels="channels" :locations="locations" :start-time-in-millis="date.startTimeInMillis"
				:end-time-in-millis="date.endTimeInMillis" :time-interval-from="timeIntervalFrom"
				:time-interval-to="timeIntervalTo" style="margin-bottom: 20px;" />
		</div>
		<div :key="transientRefreshKey"
			v-if="selectedAppBarTab === selectedTab.key && selectedTab.key === 'end_of_day'">
			<v-row dense>
				<v-col cols="12" md="6">
					<v-btn class="mb-3 primary" block href="https://admin.salescloud.is/sales/end-of-day"
						target="_blank">
						Open old end of day report
					</v-btn>
				</v-col>
				<v-col cols="12" md="6">
					<v-btn class="mb-3 primary" block @click="exportToPDF">
						Export To PDF
					</v-btn>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" md="6">
					<TransientDash ref="EOD1" v-for="date in datesToUse" :key="date.key + 'EOD1'"
						:title="'Gross Revenue: ' + titleWithDate(date)" namespace="gross_revenue" :config="{}"
						:fixed-header="selectedTab.transientDashOptions.useFixedHeader"
						:disclaimer="selectedTab.disclaimer" :channels="channels" :locations="locations"
						:start-time-in-millis="date.startTimeInMillis" :end-time-in-millis="date.endTimeInMillis"
						:time-interval-from="timeIntervalFrom" :time-interval-to="timeIntervalTo"
						style="margin-bottom: 20px;" />
				</v-col>
				<v-col cols="12" md="6">
					<TransientDash ref="EOD2" v-for="date in datesToUse" :key="date.key + 'EOD2'"
						:title="'Partially Paid Orders: ' + titleWithDate(date)" namespace="partially_paid_order_count"
						:config="{}" :fixed-header="selectedTab.transientDashOptions.useFixedHeader"
						:disclaimer="selectedTab.disclaimer" :channels="channels" :locations="locations"
						:start-time-in-millis="date.startTimeInMillis" :end-time-in-millis="date.endTimeInMillis"
						:time-interval-from="timeIntervalFrom" :time-interval-to="timeIntervalTo"
						style="margin-bottom: 20px;" />
				</v-col>
			</v-row>
			<TransientDash ref="EOD3" v-for="date in datesToUse" :key="date.key + 'EOD3'"
				:title="'Payments: ' + titleWithDate(date)" namespace="payments_summary" :config="{}"
				:fixed-header="selectedTab.transientDashOptions.useFixedHeader" :disclaimer="selectedTab.disclaimer"
				:channels="channels" :locations="locations" :start-time-in-millis="date.startTimeInMillis"
				:end-time-in-millis="date.endTimeInMillis" :time-interval-from="timeIntervalFrom"
				:time-interval-to="timeIntervalTo" style="margin-bottom: 20px;" />
			<TransientDash ref="EOD6" v-for="date in datesToUse" :key="date.key + 'EOD6'"
				:title="'Giftcard Usage Summary: ' + titleWithDate(date)" namespace="gifcard_usage_summary_table"
				:config="{}" :fixed-header="selectedTab.transientDashOptions.useFixedHeader"
				:disclaimer="selectedTab.disclaimer" :channels="channels" :locations="locations"
				:start-time-in-millis="date.startTimeInMillis" :end-time-in-millis="date.endTimeInMillis"
				:time-interval-from="timeIntervalFrom" :time-interval-to="timeIntervalTo"
				style="margin-bottom: 20px;" />
			<TransientDash ref="EOD4" v-for="date in datesToUse" :key="date.key + 'EOD4'"
				:title="'Invoiced: ' + titleWithDate(date)" namespace="invoice_summary" :config="{}"
				:fixed-header="selectedTab.transientDashOptions.useFixedHeader" :disclaimer="selectedTab.disclaimer"
				:channels="channels" :locations="locations" :start-time-in-millis="date.startTimeInMillis"
				:end-time-in-millis="date.endTimeInMillis" :time-interval-from="timeIntervalFrom"
				:time-interval-to="timeIntervalTo" style="margin-bottom: 20px;" />
			<TransientDash ref="EOD5" v-for="date in datesToUse" :key="date.key + 'EOD5'"
				:title="'Items: ' + titleWithDate(date)" namespace="item_summary_list" :config="{}"
				:fixed-header="selectedTab.transientDashOptions.useFixedHeader" :disclaimer="selectedTab.disclaimer"
				:channels="channels" :locations="locations" :start-time-in-millis="date.startTimeInMillis"
				:end-time-in-millis="date.endTimeInMillis" :time-interval-from="timeIntervalFrom"
				:time-interval-to="timeIntervalTo" style="margin-bottom: 20px;" />
			<TransientDash ref="EOD7" v-for="date in datesToUse" :key="date.key + 'EOD7'"
				:title="'Sales Tax: ' + titleWithDate(date)" namespace="sales_tax" :config="{}"
				:fixed-header="selectedTab.transientDashOptions.useFixedHeader" :disclaimer="selectedTab.disclaimer"
				:channels="channels" :locations="locations" :start-time-in-millis="date.startTimeInMillis"
				:end-time-in-millis="date.endTimeInMillis" :time-interval-from="timeIntervalFrom"
				:time-interval-to="timeIntervalTo" style="margin-bottom: 20px;" />
			<v-alert color="blue-grey" dark dense icon="mdi-school" prominent
				v-if="selectedTab.disclaimer !== null && selectedTab.disclaimer !== undefined">
				{{ selectedTab.disclaimer }}
			</v-alert>
		</div>
	</v-container>
</template>

<script>
import FilterHeader from "@/components/universalFilter/FilterHeader.vue"
import TransientDash from "@/components/dash/TransientDash"

export default {
	name: "Report",
	components: {
		TransientDash,
		FilterHeader
	},
	data() {
		return {
			showDashTypesDialog: false,
			showDashTypeDialog: false,
			showAddDashCollection: false,
			oneDayInMillis: 86400000,
			transientRefreshKey: 0,
			filterHeaderRefreshKey: -1,
			monthStartInMillis: 0,
			startTimeInMillis: 0,
			endTimeInMillis: 0,
			dayStartsAtInMillis: null,
			dayEndsAtInMillis: null,
			timeIntervalFrom: '',
			timeIntervalTo: '',
			locations: [],
			channels: [],
			startOfDay: [],
			monthDates: [],
			firstAndLastDay: [],
			loadedTabs: [],
		}
	},
	created() {
		this.$store.commit("updateAppBarTabs", this.tabs)
		// this.$store.commit('updateAppBarExport', {
		// 	color: 'pink',
		// 	callback: this.exportCustomers
		// })
		if (Array.isArray(this.tabs) && this.tabs.length > 0) {
			if (this.tabs[0].key) {
				this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
			}
		}
		this.startTimeInMillis = new Date().getTime() - this.oneDayInMillis * 7
		this.endTimeInMillis = new Date().getTime()
		this.updateMonthDates()
		this.updateFirstAndLastDay()
	},
	computed: {
		inIframe: {
			set: function (inIframe) {
				this.$store.commit("updateInIframe", inIframe)
			},
			get: function () {
				return this.$store.state.inIframe
			},
		},
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		selectedTab() {
			return this.tabs.find(tab => tab.key === this.selectedAppBarTab)
		},
		datesToUse() {
			if (this.selectedTab.useFirstAndLastDay === true) {
				return this.firstAndLastDay
			}
			if (this.selectedTab.useMonthDates === true) {
				return this.monthDates
			}
			return null
		},
		tabs() {
			return [
				{
					title: "End of Day",
					key: "end_of_day",
					namespace: "end_of_day",
					description: "A summary of payments by day for the selected month.",
					disclaimer: "This report aggregates pending & approved payments between dates of when payments were created and sums it together.",
					filterFields: {
						useDate: true,
						useChannels: true,
						useLocations: true,
						initializeTimeToToday: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: "Payments by Day",
					key: "payments-by-day",
					namespace: "payments_summary",
					description: this.$t('paymentsByDayDescription'),
					disclaimer: this.$t('paymentReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useMonthDates: true
				},
				{
					title: this.$t('paymentsByMonth'),
					key: "payments-by-month",
					namespace: "payments_summary",
					description: this.$t('paymentsByMonthDescription'),
					disclaimer: this.$t('paymentReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('paymentsByPeriod'),
					key: "payments-by-period",
					namespace: "payments_summary",
					description: this.$t('paymentsByPeriodDescription'),
					disclaimer: this.$t('paymentReportsDisclaimer1'),
					filterFields: {
						useDate: true,
						useMonth: false,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('paymentsDetailed'),
					key: "payments-detailed",
					namespace: "payments_list",
					description: this.$t('paymentsDetailedDescription'),
					disclaimer: this.$t('paymentReportsDisclaimer2'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('categoriesByDay'),
					key: "categories-by-day",
					namespace: "category_summary_list",
					description: this.$t('categoriesByDayDescription'),
					disclaimer: this.$t('categoryReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useMonthDates: true
				},
				{
					title: this.$t('categoriesByMonth'),
					key: "categories-by-month",
					namespace: "category_summary_list",
					description: this.$t('categoriesByMonthDescription'),
					disclaimer: this.$t('categoryReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('itemsByDay'),
					key: "items-by-day",
					namespace: "item_summary_list",
					description: this.$t('itemsByDayDescription'),
					disclaimer: this.$t('itemReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useMonthDates: true
				},
				{
					title: this.$t('itemsByMonth'),
					key: "items-by-month",
					namespace: "item_summary_list",
					description: this.$t('itemsByMonthDescription'),
					disclaimer: this.$t('itemReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('itemsByPeriod'),
					key: "items-by-period",
					namespace: "item_summary_list",
					description: this.$t('itemsByPeriodDescription'),
					disclaimer: this.$t('itemReportsDisclaimer1'),
					filterFields: {
						useDate: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('itemsDetailed'),
					key: "items-detailed",
					namespace: "item_list",
					description: this.$t('itemsDetailedDescription'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('invoiceList'),
					key: "invoices",
					namespace: "invoices_list",
					description: this.$t('invoiceListDescription'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useMonthDates: true
				},
				{
					title: this.$t('salesVsPaymentsByDays'),
					key: "debit-credit-by-days",
					namespace: "debit_credit_list",
					description: this.$t('salesVsPaymentsByDaysDescription'),
					disclaimer: this.$t('salesVsPaymentsReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useMonthDates: true
				},
				{
					title: this.$t('salesVsPaymentsByMonth'),
					key: "debit-credit-by-month",
					namespace: "debit_credit_list",
					description: this.$t('salesVsPaymentsByMonthDescription'),
					disclaimer: this.$t('salesVsPaymentsReportsDisclaimer1'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('salesTaxByMonth'),
					key: "sales_tax",
					namespace: "sales_tax",
					description: this.$t('salesTaxByMonthDescription'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				},
				{
					title: this.$t('workHourSummary'),
					key: "work-hour-summary",
					namespace: "work_hours_summary",
					description: this.$t('workHourSummaryDescription'),
					filterFields: {
						useMonth: true,
						useChannels: true,
						useLocations: true,
						useDayStartsAt: true,
						useDayEndsAt: true,
					},
					transientDashOptions: {
						useFixedHeader: false
					},
					useFirstAndLastDay: true
				}
			]
		},
		dashTypes() {
			return this.$store.state.dashTypes
		}
	},
	mounted() {
		this.$store.commit("updateAppBarTabs", this.tabs)
		let key = this.tabs[0].key

		if (this.$route.hash !== null && this.$route.hash !== '' && this.tabs.find(tab => tab.key === this.$route.hash.substr(1)) !== undefined) {
			key = this.tabs.find(tab => tab.key === this.$route.hash.substr(1)).key
		}

		this.$store.commit("updateSelectedAppBarTab", key)

		if (this.inIframe === true) {
			this.$store.commit("updateInApp", false)
			this.$store.commit('updateShowAppBar', true)
		}
	},
	methods: {
		exportToPDF() {
			const innerHTML1 = this.$refs.EOD1[0].$el.innerHTML
			const innerHTML2 = this.$refs.EOD2[0].$el.innerHTML
			const innerHTML3 = this.$refs.EOD3[0].$el.innerHTML
			const innerHTML4 = this.$refs.EOD4[0].$el.innerHTML
			const innerHTML5 = this.$refs.EOD5[0].$el.innerHTML
			const innerHTML6 = this.$refs.EOD6[0].$el.innerHTML
			const innerHTML7 = this.$refs.EOD7[0].$el.innerHTML
			const innerHTML = `${innerHTML1}${innerHTML2}${innerHTML3}${innerHTML6}${innerHTML4}${innerHTML5}${innerHTML7}`
			const fileName = `End of Day - ${this.$moment(this.startTimeInMillis).toISOString()} - ${this.$moment(this.endTimeInMillis).toISOString()}`
			this.$store.commit('exportPDF', {
				fileName,
				html: innerHTML
			})
		},
		changeTabs() {
			this.showAddDashCollection = false
			this.$store.commit("updateSelectedAppBarTab", this.tabs[0].key)
		},
		updateFirstAndLastDay() {
			this.firstAndLastDay = [
				{
					key: Math.random(),
					startTimeInMillis: this.startTimeInMillis,
					endTimeInMillis: this.getAbsoluteEndTimeInMillis(this.endTimeInMillis)
				}
			]
			if (typeof this.dayStartsAtInMillis !== 'undefined' && this.dayStartsAtInMillis !== null && typeof this.dayEndsAtInMillis !== 'undefined' && this.dayEndsAtInMillis !== null) {
				if (this.dayStartsAtInMillis >= this.dayEndsAtInMillis) {
					this.firstAndLastDay[0].endTimeInMillis += this.getAbsoluteEndTimeInMillis(this.oneDayInMillis)
				}
			}
		},
		getNumberOfDaysInMonth(year, month) {
			return new Date(year, month + 1, 0).getDate()
		},
		getFirstDayOfMonthInMillis(year, month) {
			const firstDay = new Date(year, month, 1)
			return firstDay.getTime()
		},
		updateMonthDates() {
			// Returns a list of objects each containing the start time and end time at 00:00 & 23:59 respectively
			// for each day between the first date (this.startTimeInMillis) and the last (this.endTimeInMillis).
			let dateList = []
			const date = new Date(this.startTimeInMillis)
			const today = new Date()
			const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime() + 24 * 60 * 60 * 1000

			let firstDayOfMonth = 0
			let daysInMonth = 0

			firstDayOfMonth = this.getFirstDayOfMonthInMillis(
				date.getFullYear(),
				date.getMonth()
			)
			daysInMonth = this.getNumberOfDaysInMonth(
				date.getFullYear(),
				date.getMonth()
			)

			let startTime = firstDayOfMonth
			if (typeof this.dayStartsAtInMillis !== 'undefined' && this.dayStartsAtInMillis !== null) {
				startTime += this.dayStartsAtInMillis
			}

			let endTime = startTime + this.oneDayInMillis - 1
			if (typeof this.dayEndsAtInMillis !== 'undefined' && this.dayEndsAtInMillis !== null) {
				endTime = firstDayOfMonth + this.dayEndsAtInMillis
			}

			for (let i = 1; i <= daysInMonth; i++) {
				if (startTime < tomorrow) {
					dateList.push({
						key: i,
						startTimeInMillis: startTime,
						endTimeInMillis: this.getAbsoluteEndTimeInMillis(endTime)
					})
					startTime += this.oneDayInMillis
					endTime += this.oneDayInMillis
				}
			}
			this.monthDates = dateList
		},
		titleWithDate(currentDate) {
			const startString = new Date(currentDate.startTimeInMillis)
				.toString()
				.slice(0, 24)
			const endString = new Date(currentDate.endTimeInMillis - 1)
				.toString()
				.slice(0, 24)

			if (typeof this.timeIntervalFrom !== 'undefined' && this.timeIntervalFrom !== null && this.timeIntervalFrom !== '' && typeof this.timeIntervalTo !== 'undefined' && this.timeIntervalTo !== null && this.timeIntervalTo !== '') {
				let theDayAfter = ''
				if (this.timeIntervalTo <= this.timeIntervalFrom && this.startTimeInMillis < this.endTimeInMillis) {
					theDayAfter = ' ' + this.$t('theDayAfter')
				}
				return startString.slice(0, 10) + " - " + endString.slice(0, 10) + ' ' + this.$t('intervalBetweenSlots') + ' ' + this.timeIntervalFrom + " - " + this.timeIntervalTo + theDayAfter + '.'
			}

			return startString + " - " + endString
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, '0')
		},
		convertMsToHM(milliseconds) {
			let seconds = Math.floor(milliseconds / 1000)
			let minutes = Math.floor(seconds / 60)
			let hours = Math.floor(minutes / 60)
			minutes = minutes % 60
			return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`
		},
		updateTimeIntervalsFromAndTo() {
			if (typeof this.dayStartsAtInMillis !== 'undefined' && this.dayStartsAtInMillis !== null && typeof this.dayEndsAtInMillis !== 'undefined' && this.dayEndsAtInMillis !== null) {
				this.timeIntervalFrom = this.convertMsToHM(this.dayStartsAtInMillis)
				this.timeIntervalTo = this.convertMsToHM(this.dayEndsAtInMillis)
			}
			else {
				this.timeIntervalFrom = ''
				this.timeIntervalTo = ''
			}
		},
		updateValues(payload) {
			this.startTimeInMillis = payload.startTimeInMillis
			this.endTimeInMillis = this.getAbsoluteEndTimeInMillis(payload.endTimeInMillis)

			this.dayStartsAtInMillis = payload.dayStartsAtInMillis
			this.dayEndsAtInMillis = payload.dayEndsAtInMillis
			if (typeof this.dayStartsAtInMillis !== 'undefined' && this.dayStartsAtInMillis !== null) {
				this.startTimeInMillis += this.dayStartsAtInMillis
			}
			if (typeof this.dayEndsAtInMillis !== 'undefined' && this.dayEndsAtInMillis !== null) {
				this.endTimeInMillis = this.endTimeInMillis + this.dayEndsAtInMillis - this.oneDayInMillis
			}
			this.locations = payload.locations
			this.channels = payload.channels
			this.updateTimeIntervalsFromAndTo()
			this.updateMonthDates()
			this.updateFirstAndLastDay()
			this.transientRefreshKey += 1
		},
		getAbsoluteEndTimeInMillis(endTimeInMillis) {
			// Round up to the end of the minute (59 seconds, 999 milliseconds)
			return Math.floor(endTimeInMillis / 60000) * 60000 + 59999
		}
	},
	watch: {
		selectedAppBarTab() {
			this.filterHeaderRefreshKey -= 1
		}
	}
}
</script>
